<template>
  <div class="col-12">
    <img src="@/assets/img/fibra.png" alt="" class="img-fluid col-12" />
  </div>
  <div v-for="(item, index) in items" :key="index" class="col-12">
  <AccordionComponent  :accordionKey="index" class="mb-1 col-12" style="cursor: pointer">
    <template v-slot:title>
      <span class="colorWhite row" style="font-size: 25px">{{ item.title }}</span>
    </template>
    <template v-slot:content>
      <p class="colorBlack px-2 mt-2">{{ item.content }}</p>
    </template>
  </AccordionComponent>
  </div>
  <div class="bgColorGray">
    <div class="row container">
      <div class="mt-3">
        <p class="d-flex justify-content-start justify-content-md-center">
          <strong>Produto selecionado: </strong>
        </p>
      </div>
    </div>
    <div class="container-fluid mt-1">
      <ProductsCatalog
        :products="productsComputed"
        :add-product="addProduct"
        :open-cart="openCart"
      />
    </div>
  </div>
  <div class="col-12 d-flex align-items-center justify-content-center mt-3">
    <div class="row">
      <p class="mt-3 mb-4 px-4" style="font-size: 24px">
        <strong>Perguntas Frequentes</strong>
      </p>
    </div>
  </div>
  <div class="col-12 d-flex align-items-center justify-content-center">
    <div class="row">
      <p class="mb-4 px-4" style="font-size: 16px">
        Essas respostas podem ajudar
      </p>
    </div>
  </div> 
<div v-for="(item, index) in accordionItems" :key="index" class="col-12 mt-0 d-flex">
  <Accordion :accordionKey="index" class="mb-1" style="cursor: pointer">
    <template v-slot:title>
      <span class="colorBlack" style="font-size: 14px">{{ item.title }}</span>
    </template>
    <template v-slot:content>
      <p class="colorBlack px-4 mt-2">{{ item.content }}</p>
    </template>
  </Accordion>
</div>
  <ViabilityModal v-if="showViabilityModal" />
</template>
<script>
import Accordion from "@/components/Accordion/McafeeHpAccordionComponent";
import AccordionComponent from "@/components/Accordion/ClaroAccordion.vue";
import ProductsCatalog from "@/components/Sale/ProductsCatalog";
import Configuration from "@/services/configuration";
import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
import ViabilityModal from "@/components/Sale/Viability/ViabilityModal.vue"
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      showViabilityModal: false,
      accordionItems: [
        {
          title: "O que é Fibra Ótica?",
          content: "A fibra ótica é a tecnologia de conexão de internet mais rápida do mundo. As fibras, compostas de vidro, transmitem os dados por longas distâncias através da luz. Com a internet fibra você tem ultravelocidade e maior estabilidade na conexão; conheça aqui os planos."
        },
        {
          title: "A instalação da fibra ótica demora quanto tempo?",
          content: "A instalação da internet fibra ótica acontece em aproximadamente 1 hora. Pode ser que no local existam variáveis que aumentem esse tempo, mas essa avaliação é feita pelo técnico especialista. Veja aqui o plano de internet ideal pra você!"
        },
        {
          title: "Por que a fibra ótica é uma tecnologia melhor?",
          content: "A fibra ótica é considerada a melhor tecnologia de internet do mundo.Com transmissão de dados na velocidade da luz, a fibra oferece ultravelocidade e estabilidade na conexão. As vantagens da internet fibra são: mais alcance e durabilidade, menor desperdício de sinal, múltiplos dispositivos conectados sem alteração na velocidade, além de não sofrer com interferências externas como chuvas ou ventanias."
        },
        {
          title: "O que é velocidade de download e upload?",
          content: "O download representa quantos arquivos você recebe e o upload quantos arquivos você envia, ou seja, se trata da velocidade disponível para baixar ou enviar vídeos, imagens, músicas, documentos e qualquer arquivo online."
        },
        {
          title: "O que é Claro Net Virtua?",
          content: "A Claro Net Virtua é a internet banda larga da operadora, ou seja, aquela que você pode ser contratar para usar na sua casa. Ela é chamada dessa forma devido à fusão entre a Claro e a net."
        },
        {
          title: "A internet da Claro é boa?",
          content: "Sim! A internet Claro é boa e você pode contratá-la neste site em poucos cliques. E, se preferir, pode ligar para 0800 338 2121 e contratar com um de nossos atendentes. Importante: antes de escolher um plano, verifique a cobertura Claro na sua região."
        }        
      ],
      items: [
        {
          title: "Claro Fibra",
          content: " Distribuição da velocidade Ideal para quem busca velocidade e benefícios em 10 dispositivos com valor mais acessível Assista filmes e séries com tranquilidade. Jogue a vontade sem travar! Trabalhe ou estude a distância sem se preocupar."
        }
      ]
    };
  },
  props: {
    title: String
  },
  name: "Homepage",
  components: {
    Accordion,
    ProductsCatalog,
    AccordionComponent,
    ViabilityModal,
  },
  computed: {
    ...mapGetters({
      productsComputed: "saleFlow/product/getProducts",
      getCurrentAddressComputed: "address/getCurrentAddress",
      authenticatedCustomerComputed: 'auth/AuthorizationCustomer',
    })
  },
  mounted() {
    this.startRegionalizationFlow();
  },
  methods: {
    ...mapActions({
      getAllProductsCatalogAction: "saleFlow/getAllProductsCatalog",
      setAvailableProductTypeAction: "saleFlow/product/setAvailableProductType",
      addProductCartAction: "cart/addProductCart",
      openCartAction: "cart/openCart",
      openRegionalizationModalAction: "address/openRegionalizationModal",
      setRegionalizationModalCallbackAction:"address/setRegionalizationModalCallback"
    }),
    getProducts() {
      this.setAvailableProductTypeAction([
        Configuration.productTypeOption[
          productTypesOptionsConstants.CLARO_RESIDENTIAL_BANDA_LARGA
        ]
      ]).then(() => this.getAllProductsCatalogAction());
    },
    addProduct({ product, promotion }) {
      this.openViability();
      this.addProductCartAction({ product, promotion });
    },
    openCart() {
      this.openCartAction();
    },
    openViability() {
      this.showViabilityModal = true
    },
    startRegionalizationFlow() {
      if (this.getCurrentAddressComputed?.id) {
        this.getProducts();
        return;
      }
      this.openRegionalizationModalAction();
      this.setRegionalizationModalCallbackAction(() => {
        this.getProducts();
      });
    },
  }
};
</script>
 <style lang="scss" scoped>
.h1 {
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free";
  font-size: 20px;
  color: #000000;
}
.cardRed {
  color: #ffffff;
  background-color: crimson;
}
.cardWhite {
  color: crimson;
  background-color: #ffffff;
}
.colorBlack {
  color: #000;
}
.colorRed {
  color: crimson;
}
.colorWhite {
  color: #ffffff;
  font-weight: 700;
  font-family: Roboto;
}
.bgColorGray {
  background-color: #f3f3f3;
}
.fontSmall {
  font-size: 12px;
}
.fontMedium {
  font-size: 22px;
}
.gradRed {
  background-image: linear-gradient(deeppink, red);
}
</style>
