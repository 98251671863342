<template>
  <div>
    <HomeContainer>
      <template v-slot:header>
        <Header :logoRedirectUrl="'/clarotv'"  />
      </template>
      <template v-slot:adressbar>
        <AddressBar />
      </template>
      <template v-slot:main>
        <Homepage :title="pageTitle" />
      </template>
      <template v-slot:footer>
        <Footer />
      </template>      
    </HomeContainer>
  </div>
</template>
  <script>
import Footer from "@/components/Footer/Footer.vue";
import Homepage from "@/components/Homepages/claro/tv/HomePage.vue";
import Header from "@/components/Header/Header.vue";
import HomeContainer from "@/components/Container/HomeContainer.vue";
import AddressBar from "@/components/Header/AddressBar.vue";

export default {
  name: "HomeClaroTv",
  components: {
    Header,
    Homepage,
    Footer,
    HomeContainer,
    AddressBar
  },
  data() {
    return {
      pageTitle: 'Bem vindo a página da Claro'
    }
  }
};
</script>
