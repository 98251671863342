
<template>
  <div class="wrapper">
    <HomeContainer class="content">
      <template v-slot:header>
        <Header />
      </template>
      <template v-slot:main>
        <div class="up-container">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-10 col-md-6 col-lg-4">
                <div class="row">
                  <span class="container text-title"> Alteração de senha </span>
                </div>
                <div class="row mt-1">
                  <span class="container text-subtitle">
                    Código de verificação
                  </span>
                  <div class="col-12">
                    <b-form-input
                      v-model="customer.code"
                      type="password"
                      class="text-input form-control"
                      placeholder="Código de verificação"
                      required >
                    </b-form-input>
                    <span v-if="!customer.code" class="text-subtitle text-danger">
                      O campo de código de verificação é obrigatório.
                    </span>
                  </div>
                </div>
                <div class="row mt-1">
                  <span class="container text-subtitle"> Nova senha </span>
                  <div class="col-12">
                    <b-form-input
                      v-model="customer.password"
                      type="password"
                      class="text-input form-control"
                      placeholder="Nova senha"
                      required>
                    </b-form-input>
                    <span v-if="customer.password && !isStrongPassword(customer.password)" class="text-subtitle text-danger">
                      Senha fraca
                      <br />
                    </span>
                    <span v-else-if="customer.password" class="text-subtitle text-success">
                      Senha forte
                    </span>
                  </div>
                </div>
                <div>
                  <div class="row">
                    <div class="col-12">
                      <span class="text-password">
                        <span
                          :class="{
                            'text-success': hasUppercase,
                            'text-password': !hasUppercase, 
                            }">
                            ter pelo menos uma letra maiúscula: {{ hasUppercase ? '✔' : '' }}</span><br />
                        <span
                          :class="{
                            'text-success': hasNumber,
                            'text-password': !hasNumber,
                          }">
                          ter pelo menos um número: {{ hasNumber ? '✔' : '' }}</span><br />
                        <span
                          :class="{
                            'text-success': hasSpecialChar,
                            'text-password': !hasSpecialChar,
                          }">
                          ter pelo menos um caractere especial: {{ hasSpecialChar ? '✔' : '' }}</span><br />
                        <span
                          :class="{
                            'text-success': isLongEnough,
                            'text-password': !isLongEnough,
                          }">
                          ter no mínimo 8 dígitos: {{ isLongEnough ? '✔' : '' }}</span><br />
                      </span>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <span class="container text-subtitle">
                    Confirme a senha
                  </span>
                  <div class="col-12">
                    <b-form-input
                      v-model="customer.passwordRepeat"
                      type="password"
                      class="text-input form-control"
                      placeholder="Confirmar senha"
                      required>
                    </b-form-input>
                    <span v-if="customer.password && customer.passwordRepeat !== customer.password" class="text-subtitle text-danger">
                      A confirmação de senha deve ser igual à nova senha.
                    </span>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <button
                    type="button"
                    class="btn border rounded col-12 col-md-6 col-lg-6"
                    style="color: #fff; background-color: #1d3c85"
                    @click="updateNewPassword"
                    :disabled="isUpdateButtonDisabled">
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <Footer />
        </div>
      </template>
    </HomeContainer>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { forgotPassword } from "@/modules/auth/services/auth.customer.service.js";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import HomeContainer from "@/components/Container/HomeContainer.vue";
export default {
  name: "ForgotPassword",
  components: {
    Header,
    Footer,
    HomeContainer
  },
  data() {
    return {
      customer: {
        email: null,
        code: null,
        password: null,
        passwordRepeat: null
      }
    };
  },
  computed: {
    ...mapGetters({
      getPartnerConfigurationComputed: "getPartnerConfiguration",
      getCustomerComputed: "customer/getCustomer"
    }),
    isUpdateButtonDisabled() {
      return (
        !this.customer.code ||
        !this.customer.password ||
        !this.customer.passwordRepeat ||
        !this.isStrongPassword(this.customer.password) ||
        this.customer.passwordRepeat !== this.customer.password
      );
    },
    hasUppercase() {
      const password = this.customer.password;
      const uppercaseRegex = /[A-Z]/;
      return uppercaseRegex.test(password);
    },
    hasNumber() {
      const password = this.customer.password;
      const numberRegex = /[0-9]/;
      return numberRegex.test(password);
    },
    hasSpecialChar() {
      const password = this.customer.password;
      const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]/;
      return specialCharRegex.test(password);
    },
    isLongEnough() {
      const password = this.customer.password;
      return password && password.length >= 8;
    }
  },
  methods: {
    ...mapActions({
      openCartAction: "cart/openCart",
    }),
    async updateNewPassword() {
      const { password, passwordRepeat, code } = this.customer;
      const { email } = this.getCustomerComputed;
      try {
        await forgotPassword(email, password, passwordRepeat, code);
        this.$router.push({ name: "AlterarSenha" });
      } catch {
        this.$swal(
          "O código de verificação é inválido, tente novamente.",
          "",
          "error"
        );
      }
    },
    isStrongPassword(password) {
      const strongPasswordRegex =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      return strongPasswordRegex.test(password);
    }
  },
};
</script>
<style lang="scss">
.wrapper {
  display: flex;
  min-height: 100vh;
  background-color: #fff;
}
.bgForgotPassword {
  background-color: rgb(61, 61, 175);
  color: #fff;
}
.text-input {
  font-size: 12px;
  color: #252525;
}
.text-title {
  font-size: 16px;
  color: #1d3c85;
  font-weight: bold;
}
.text-subtitle {
  font-size: 14px;
  color: #999797;
  font-weight: 700;
  font-family: roboto;
}
.text-success {
  color: green;
  font-size: 11px;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.up-container {
  margin-bottom: 64.5%;
}
.text-password {
  font-size: 11px;
  color: #9b9a9a;
}
.text-success {
  color: green;
  font-size: 11px;
}
</style>
