<template>
  <div class="container-fluid">
      <div class="row py-2">
        <div class="col-12">
          <Modal :modal-active="showModal">
            <template v-slot:default="{}">
              <div class="move-modal">
                 <div class="row col-12 Text mt-1 container">
                    <span class="container-fluid mt-1 text-step">
                      Olá! Tudo bem ? 
                      <i class="fas fa-times icon-close  close-button" @click="close"></i>
                    </span>               
                  </div>
                  <div class="mt-3 text2 container" style="color:#141414">
                    Coloque seus dados para verificarmos a<br>
                    disponibilidade deste produto
                  </div>
                  <div class="container-fluid mt-3">
                    <div class="row">
                      <div class="col-12 col-sm-12 text-input mt-2">
                        <span>CPF*</span>
                        <input
                          type="text" 
                          label="CPF" 
                          class="form-control text-input" 
                          placeholder="CPF"
                          v-model="customer.cpf"
                          v-mask="'###.###.###-##'" 
                          @input="validateFormCustomer('cpf')"
                        />
                        <small class="form-text text-danger">{{ res.getErrors('cpf')[0] }}</small>
                      </div>
                    </div>
                    <div>
                    <div class="row">
                      <div class="col-12 col-sm-12 text-input mt-2">
                        <span>Nome*</span>
                        <input
                          type="text"
                          label="Nome"
                          class="form-control text-input"
                          placeholder="Nome" required
                          v-model="customer.name" 
                          @input="validateFormCustomer('name')"
                        />
                        <small class="form-text text-danger">{{ res.getErrors('name')[0] }}</small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 text-input mt-2">
                        <span>Nome da mãe*</span>
                        <input
                          type="text"
                          label="Nome"
                          class="form-control text-input"
                          placeholder="Nome da mãe"
                          v-model="customer.motherName"
                          @keypress="validateFormCustomer('motherName')"
                          @input="validateMotherName"
                        />
                          <span class="form-text text-danger">{{ motherNameError }}</span>
                        
                      </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-12 text-input mt-2">
                          <span>Email*</span>
                          <input
                            type="text"
                            label="Email"
                            class="form-control text-input"
                            placeholder="Email"
                            v-model="customer.email"
                            @input="validateFormCustomer('email')"
                          />
                          <small class="form-text text-danger">{{ res.getErrors('email')[0] }}</small>
                        </div>
                      </div>
                        <div class="row">
                      <div class="col-12 col-sm-12 text-input mt-2">
                        <span>Data de nascimento*</span>
                          <DatePicker
                            @input="selectDateBirth"
                            :birthDate="true"
                            class="col-12 fontData"                               
                          />  
                        <small  class="form-text text-danger">{{ res.getErrors('birthdate')[0] }}</small>
                      </div>
                        </div>
                      <div class="mt-1">
                        <div class="row">
                          <div class="col-12 mt-1">
                            <div class="row">
                              <div class="col-6 col-sm-8 text-input">
                                <span>CEP*</span>
                                  <input
                                    type="text"
                                    label="CEP"
                                    class="form-control text-input"
                                    placeholder="00000-000" disabled
                                    v-model="filledAddress.postalCode"
                                    v-mask="'#####-###'"
                                  />
                              </div>
                              <div class="col-6 mt-3 col-sm-4 text-input">
                                <button 
                                  @click="openModalRegionalization()"
                                  class="btn-sm col-11 btn btn-primary border-1 rounded">
                                  <span> Alterar</span>
                                </button>
                              </div>
                              <div class="col-12 col-sm-12 text-input mt-2">
                                <span>Endereço*</span>
                                <input
                                  type="text" 
                                  label="Endereço" 
                                  class="form-control text-input"
                                  placeholder="Rua, Avenida, etc..."
                                  v-model="filledAddress.streetName" 
                                  disabled                                  
                                />
                              </div>
                              <div class="col-6 col-sm-6 text-input mt-2">
                                <span>Número*</span>
                                <input 
                                  type="text" 
                                  label="Número" 
                                  class="form-control text-input" 
                                  placeholder="Ex:123"
                                  v-model="filledAddress.number"
                                  @input="validateFormAddress('number')" 
                                />
                                <small class="form-text text-danger">{{resA.getErrors("number")[0]}}</small>
                              </div>
                              <div class="col-6 col-sm-6 text-input mt-2">
                                <span>Complemento</span>
                                <input
                                  type="text" 
                                  label="Complemento" 
                                  class="form-control text-input"
                                  placeholder="Casa, Apartamento, etc..." 
                                  v-model="filledAddress.complement"
                                  @input="validateFormAddress('complement')"
                                />
                                <small class="form-text text-danger">{{resA.getErrors("complement")[0]}}</small>
                              </div>                           
                              <div class="container-fluid col-12 mt-2 container-checkbox text-center"><br>
                                <div class="col-12">
                                  <button 
                                    pill variant="danger" 
                                    type="submit" 
                                    value="Submit"
                                    class="btn btn-primary text-center col-12 col-sm-6 col-md-12 col-lg-6 loading-button"
                                    @click="nextStep" 
                                    :disabled="isButtonDisabled">
                                    <span v-if="showContinueButton">Continuar</span>
                                    <span v-else>
                                      <div class="text-center">
                                        <strong class="text-sm ">Aguarde o processamento......</strong>
                                        <div class="spinner-border ml-auto" role="status" aria-hidden="true" style="width: 16px; height: 16px;"></div>
                                      </div>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>                  
                    </div>
                  </div>
              </div>
           </template>
        </Modal>
      </div>
    </div>
  </div>
  <!---has no viability--->
  <Modal :modal-active="showNoViabilityModal">
    <template v-slot:default="{}">
      <div class=" mt-2">
        <div class="mt-3">
          <div class="row">
            <span class="container text-center mt-1 text3">
              Infelizmente você não tem disponibilidade neste produto
            </span>
          </div>
          <div class="mt-3">
            <div class="row  text-center">
              <div class="text2">
                Clique em continuar para ver outras sugestões
              </div>
            </div>
          </div>
          <div class="text-center d-flex justify-content-center">  
            <b-button 
              type="button" 
              class="btn border rounded col-10 mt-4" 
              @click="close">
              Continuar
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/Modal/Modal.vue'
import suite from "@/services/validations/customerValidations.js"
import suiteA from "@/services/validations/adressValidations.js"
import { mapGetters, mapActions } from "vuex"
import DatePicker from "@/components/Calendar/DataPicker.vue";
import moment from "moment";
export default {
  name: "ViabilityModal",
  components: {
    Modal,
    DatePicker
  },
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    setProductViability: {
      type: Function,
      default: () => {}
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function
    }
  },
  data() {
    return {
      validationError: '',
      isCpfValid: true,
      processing: false,
      showModal: true,
      showNoViabilityModal: false,
      customer: {
        cpf: '',
        name: '',
        email: '',
        phone1: '00-000000000',
        birthdate: '',
        motherName: '',
        id: '',
      },
      filledAddress: {
        postalCode: "",
        streetName: "",
        number: "",
        complement: "",
        neighborhoodName: "",
        cityName: "",
        stateName: "",
        id: ""
      },
      customerAddress: {
        customerId: '',
        addressId: '',
        number: '',
        complement: '',
      },
      pointOfSale: {
        id: "",
      },
      tagOrigin: "Fluxo de vendas OnLine  TradeAppOne",
      saleOfServiceId: null,
      zipcodes: [],
      res: suite.get(),
      resA: suiteA.get(),
    }
  },
  mounted() {
    this.setCurrentPointOfSale();
    this.getDataCustomer()
  },
  computed: {
    isValid() {
      const hasValidMotherName = this.customer?.motherName?.trim().split(' ').length >= 2;
      const isValidEmail = this.customer?.email && this.validateEmail(this.customer.email);
    
      return (
        this.filledAddress?.streetName &&
        this.filledAddress?.number &&
        isValidEmail && 
        hasValidMotherName &&
        this.customer?.birthdate
      );
    },
    ...mapGetters({
      customerComputed: 'customer/getCustomer',
      customerAddressComputed: 'customer/getCustomerAddress',
      hasAvailableProductTypeClaroComputed: 'saleFlow/product/hasAvailableProductTypeClaro',
      requireShowModalAuthenticateComputed: 'saleFlow/operators/claro/authenticate/requireShowModalAuthenticate',
      hasAvailableProductTypeMcafeeComputed: 'saleFlow/product/hasAvailableProductTypeMcafee',
      isValidClaroAuthenticateLinkComputed: 'saleFlow/operators/claro/authenticate/isValidLink',
      getCartDetailsComputed: 'cart/getCartDetails',
      filledAddress: 'address/getfilledAddress',
      filledAddressComputed: "address/getAddresses",
    }),
    isButtonDisabled() {
      return this.processing || !this.isValid || !this.validateMotherName;
    },
    showContinueButton(){
      return !this.processing || !this.isValid || !this.validateMotherName;
    },
    motherNameError() {
      return this.res.getErrors('motherName')[0] || this.validationError;
    },
  },
  methods: {  
    ...mapActions({
      customerAssociateAddressAction: 'customer/associateAddress',
      customerSaveAction: 'customer/save',
      addCustomerAction: 'saleFlow/addCustomer',
      getProductsCatalogAction: 'saleFlow/getProductsCatalog',
      checkoutPaymentOptionsAction: 'saleFlow/checkoutPaymentOptions',
      validateIfProductCanBeSoldAction: 'saleFlow/validateIfProductCanBeSold',
      processCartToCheckoutAction: 'saleFlow/processCartToCheckout',
      openModalClaroAuthenticateAction: 'saleFlow/operators/claro/authenticate/openModal',
      generateClaroAuthenticateLinkAction: 'saleFlow/operators/claro/authenticate/generateLink',
      setCurrentPointOfSale: 'auth/user/pointOfSale/setCurrent',
      removeCartItemAction: 'cart/removeProductCart',
      filledAddressAction: "address/getAddresses",
      openRegionalizationModalAction: 'address/openRegionalizationModal',
      setRegionalizationModalCallbackAction: 'address/setRegionalizationModalCallback',
      openCartAction: 'cart/openCart'
    }),
    findCustomer() {
      this.$store.dispatch("customer/findByCpf", this.customer.cpf)
        .then(() => {
          const customer = this.$store.getters['customer/getCustomer'];
          if (customer !== null) {
            this.customer.name = customer.name;
            this.customer.email = customer.email;
            this.customer.phone1 = customer.phone1;
            this.customer.id = customer.id;
            this.validateFormCustomer('cpf');     
            this.validateFormCustomer('name');  
            this.validateFormCustomer('motherName'); 
            this.validateFormCustomer('email');  
          } 
        })
    },
    validateFormAddress(field) {
      this.resA = suiteA(
        {
          number: this.filledAddress.number,
          complement: this.filledAddress.complement
        },
        field
      );
    },
    disableButton() {
      this.processing = true;
    },
    enableButton() {
      this.processing = false;
    },
    convertBirthdate() {
      if (this.customer.birthdate) {
        const [day, month, year] = this.customer.birthdate.split('/');
        this.customer.birthdate = `${year}-${month}-${day}`;
      }
    },
    selectDateBirth (formattedDate) {
      this.customer.birthdate = formattedDate
    },
    validateEmail(email) {
      const emailPattern = /\S+@\S+\.\S+/;
      return emailPattern.test(email);
    },
    nextStep() {
      this.disableButton(); 
      const formattedDate = moment(this.customer.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD");
      this.convertBirthdate();       
      this.customerSaveAction(this.getCustomerPayload())
        .then(() => this.customerAssociateAddressAction(this.getAssociateAddressPayload()))
        .then(() => this.addCustomerAction(this.getCustomerPayload()))
        .then(() => this.getProductsCatalogAction())
        .then(async () => {
          const validation = await this.validateIfProductCanBeSoldAction(this.product);

          this.setProductViability(validation)

          if (validation?.validated === true) {
            this.closeViabilityModal()
            this.openCartAction()
            return Promise.resolve(this.$swal('Você tem disponibilidade para este produto!', " ", 'success'));
          }
          if (validation?.validated === false) {         
            this.showModal = false;
            this.showNoViabilityModal = true;
            return Promise.reject();
          }        
        })
    },
    getCustomerPayload() {
      return {
        cpf: this.customer.cpf,
        name: this.customer.name,
        email: this.customer.email,
        phone: this.customer.phone1,
        motherName: this.customer.motherName,
        birthdate: this.customer.birthdate,
        id: this.customerComputed?.id,
      }
    },
    getAssociateAddressPayload() {
      return {
        customerId: this.customerComputed?.id,
        addressId: this.filledAddress?.id,
        number: this.filledAddress?.number,
        complement: this.filledAddress?.complement,
        id: this.customerAddressComputed?.id,
      }
    },
    validateFormCustomer(field) {
      this.res = suite({
        cpf: this.customer.cpf,
        name: this.customer.name,
        email: this.customer.email,
        phone: this.customer.phone1,
        birthdate: this.customer.birthdate,
        motherName: this.customer.motherName
      }, field);
    },
    verifyFieldsAddress() {
      const requiredFields = [
        this.filledAddress?.number,
        this.filledAddress?.complement
      ];
      const hasEmptyField = requiredFields.some(field => {
        return !this[field] || this[field] === '';
      });
      return !hasEmptyField;
    },
    verifyFieldsStep() {
      if (!this.verifyFieldsAddress()) {
        this.enableButton();
        this.$swal('Por favor, preencha todos os campos de endereço.', " ", 'error');
        return;
      }
      if (!this.number || this.number.trim() === '') {
        this.$swal('Por favor, preencha o campo "number".', " ", 'error');
        return false;
      }
     
      this.disableButton();
      return true;
    },
    openModalRegionalization(){
      this.showModal = false;     
      this.openRegionalizationModalAction();
    },
    getDataCustomer() { 
      const filledAddress = this.$store.getters["address/getCurrentAddress"];
      if (filledAddress !== null && filledAddress !== undefined) {    
        this.filledAddress = {
          postalCode: filledAddress.postalCode,
          streetName: filledAddress.streetName,
          id:filledAddress.id
        };
      }        
    },
    openViabilityModal() {
      this.showModal = !this.showModal;
    },
    closeViabilityModal() {
      this.showModal = false;
      this.closeModal()
    },
    openNoViabilityModal() {
      this.nextStep();
      this.showModal = false;
    },
    noViability() {
      this.showNoViabilityModal = true;
    }, 
    validateMotherName() {
  try {
    const motherName = this.customer.motherName;

    if (motherName.trim().split(' ').length < 2) {
      this.validationError = 'Por favor, digite o nome completo da mãe (nome e sobrenome).';
    } else {
      const formattedMotherName = motherName
        .split(' ')
        .map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
        .join(' ');
      this.customer.motherName = formattedMotherName;
      this.validationError = '';
    }
  } catch (error) {
    console.error(error.message);
    this.validationError = error.message;
  }
}
  }
}
</script>
<style lang="scss" scoped>
.Text {
  color: #1d3c85;
  font-size: 18px;
  font-weight: 700
}
.text2 {
  color: #141414;
  font-size: 12px
}
.text3 {
  color: #1d3c85;
  font-size: 16px;
  font-weight: 500
}
.text-input {
  font-size: 11px;
  color: rgb(88, 87, 87);
}
.form-adress {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
  color: darkgray;
  font-size: 12px;
      }
.move-modal {
  margin-bottom: 0%;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

}
button {
  color: #fff;
  background-color: #1d3c85
}
.loading-button i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spinner 1s infinite linear;
}
@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.fontData{
  font-size: 9px !important;
}
.close-button {
  display: inline-block;
  top: 0;
  right: 0;
  width: 25px;
  height: 26px;
  background-color: #fff;
  border: 2px solid #1d3c85;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-close{
  float: right;
  margin-right: -22px;
  cursor: pointer;
  color: #1d3c85;
  font-size: 16px
}
.text-step{
	font-size: 17px;
	color: #1d3c85;
  font-weight: 500;
}
</style>