<template>
  <div class="container d-flex align-items-center">
    <h1 class="me-auto">
      <router-link to="/">
        <div class="header-partner-logo">
          <img :src="partnerImgSrc" alt="" class="img-auto"/>
        </div>
      </router-link>
    </h1>
    <div></div>
  </div>
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 col-lg-4 text-center">
        <Icon name="banner" class="icon-center" />
        <span class="mt-1 text-title"> Senha atualizada com sucesso! </span>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-12 col-md-6 col-lg-4 text-center">
        <button
          type="button"
          class="btn border rounded col-10"
          style="color: #fff; background-color: #1d3c85"
          @click="returnHome" >
          Ok
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ChangePassword",
  data() {
    return {
      partnerImgSrc: null
    };
  },
  computed: {
    ...mapGetters({
      getPartnerConfigurationComputed: "getPartnerConfiguration"
    })
  },
  watch: {
    getPartnerConfigurationComputed() {
      this.setPartnerConfigurationLogoSrc();
    }
  },
  mounted() {
    this.setPartnerConfigurationLogoSrc();
  },
  methods: {
    ...mapActions({
      openCartAction: "cart/openCart"
    }),
    setPartnerConfigurationLogoSrc() {
      this.getPartnerConfigurationComputed
        ?.logo()
        .then((module) => (this.partnerImgSrc = module?.default));
    },
    returnHome() {
      this.$router.push({ name: "HomeClaroTv" });
      this.openCartAction();
    }
  }
};
</script>
<style lang="scss">
.bgForgotPassword {
  background-color: rgb(61, 61, 175);
  color: #fff;
}
.text-input {
  font-size: 12px;
  color: #141414;
}
.text-title {
  font-size: 16px;
  color: #1d3c85;
  font-weight: 700;
}
.icon-center {
  width: 320px;
  margin-left: 30%;
  margin-right: 25%;
}
</style>