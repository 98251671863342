<template>
  <div class="col-12">
    <div class="col-12 ">
      <img src="@/assets/img/tv-+.png" alt="" class="img-fluid col-12 ">
    </div>
  </div>
  <div v-for="(item, index) in items" :key="index" class="col-12">
    <AccordionComponent :accordionKey="index" class="mb-1 col-12" style="cursor: pointer;">
      <template v-slot:title>
        <h4 class="colorWhite row mt-1">{{ item.title }}</h4>
      </template>
      <template v-slot:content>
        <p class="colorBlack  px-2 mt-2">{{ item.content }}</p>
      </template>
    </AccordionComponent>
  </div>
  <div class="bgColorGray ">
    <div class="row container ">
      <div class="mt-3  ">
        <p class="d-flex justify-content-start justify-content-md-center ">
          <strong>Produto selecionado:</strong>
        </p>
      </div>
    </div>
    <div class="container-fluid mt-1 ">
      <ProductsCatalog 
        :products="productsComputed" 
        :add-product="addProduct" 
        :open-cart="openCart"
      />
    </div>
  </div>
  <div class="col-12 d-flex align-items-center justify-content-center mt-3">
    <div class="row">
      <p class="mt-3 mb-4 px-4" style="font-size: 24px">
        <strong>Perguntas Frequentes</strong>
      </p>
    </div>
  </div>
  <div class="col-12 d-flex  align-items-center justify-content-center ">
    <div class="row">
      <p class="mb-4 px-4" style="font-size: 16px">
        Essas respostas podem ajudar
      </p>
    </div>
  </div>
  <div v-for="(item, index) in accordionItems" :key="index" class="col-12 mt-0 d-flex ">
  <Accordion :accordionKey="index" class="mb-1" style="cursor: pointer">
    <template v-slot:title>
      <span class="colorBlack" style="font-size: 14px">{{ item.title }}</span>
    </template>
    <template v-slot:content>
      <p class="colorBlack px-4 mt-2">{{ item.content }}</p>
    </template>
  </Accordion>
</div>
  <ViabilityModal
    v-if="showViabilityModal"
    :product="productSelectedToAddCart"
    :close-modal="closeViabilityCallback"
    :set-product-viability="setProductViabilityCallback"
    :close="close"
  />
</template>   
<script>
import Accordion from "@/components/Accordion/McafeeHpAccordionComponent"
import AccordionComponent from "@/components/Accordion/ClaroAccordion.vue"
import ProductsCatalog from "@/components/Sale/ProductsCatalog"
import Configuration from "@/services/configuration"
import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants"
import ViabilityModal from "@/components/Sale/Viability/ViabilityModal.vue"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "Homepage",
  data() {
      return {
        showViabilityModal: false,
        accordionItems: [
          {
            title: "Quais são os canais disponíveis na Claro TV?",
            content: "Cada plano Claro TV possui uma grade de programação diferente. Em todas elas você poderá assistir a canais como Disney Channel, Warner, Discovery, ESPN, SportTV e muito mais. Acesse a grade online e consulte toda a programação disponível nos planos: Conheça a grade de canais do plano Fácil HD; Conheça a grade de canais do plano Mix HD; Conheça a grade de canais do plano Top 4K;"
          },
          {
            title: "O que é NOW?",
            content: "O NOW é a plataforma de streaming da Claro exclusiva para clientes. Ela está disponível sem custo adicional em diversos planos da operadora e oferece conteúdos de filmes, séries, programas infantis, notícias ao vivo e muito mais."
          },
          {
            title: "O que são os recursos da Claro TV??",
            content: " São algumas funcionalidades inclusas na TV ou no controle remoto. Elas incluem o replay TV, para voltar e não perder nenhum detalhe do que estiver assistindo, gravador de programação e recurso PIP (Picture in Picture), para assistir até 2 conteúdos ao mesmo tempo na sua tela."
          },
          {
            title: "Qual é o telefone da Claro TV?",
            content: " O telefone da Claro TV para assinar um plano é o 0800 338 2121. Quem já é cliente pode ligar grátis no canal de atendimento 106 21."
          },
          {
            title: "O plano Fácil HD não existe mais?",
            content: "O pacote Fácil HD ainda está sendo disponibilizado pela Claro. É possível contratar das seguintes formas: • De forma individual, por R$ 79,99/mês; • No combo com internet banda larga ou móvel, por R$ 79,99/mês; • Com canais extras, podendo ser HBO ou Telecine, por R$ 89,99/mês, ou Premiere, por R$ 109,99/mês."
          },
          {
            title: "A Claro tv+ (NOW) não está funcionando, o que preciso fazer?",
            content: "A Claro tv+ funciona simultaneamente usando os sinais da rede de TV e Banda Larga, se um dos dois serviços estiver com problemas, o serviço não funcionará corretamente. Nos casos de mensagens como 'procurando rede', 'rede não encontrada' ou 'atualizando catálogo', é preciso checar se existe algum problema técnico na região ou se é necessária uma visita técnica."
          },
          {
            title: "Como alterar a senha de compra Claro tv+ (NOW)?",
            content: "A senha inicial de aluguel de conteúdo da Claro tv+ é 0000. Por segurança, sugerimos que esta senha seja alterada/personalizada. A senha de compra é a mesma senha de Bloqueio de canais (Controle de Pais).Para alterá-la, acesse 'Configurações da conta' > 'Alterar senha' no menu do clarotvmais.com.br ou no app Claro tv+." 
          }
        ],
        items: [
          {
            title: "Claro TV+",
            content: "A líder em TV e conteúdo, inovando de novo!\n\nCom + de 100 canais ao vivo, 50 mil conteúdos On Demand, milhares de filmes e séries liberados no Claro vídeo e muito mais.\n\nA Claro pensou em tudo e está transformando o melhor em excepcional. Agora você tem a liberdade de escolher como e onde quer assistir seus conteúdos favoritos da TV ao vivo e On Demand."
          }
        ],
        productSelectedToAddCart: {
        product: {},
        promotion: {},
        productResidentialPointAdditionalAmount: 0,
        productResidentialPointAdditionalType: null
      },
      productSelectedHasViability: false,
      }
    },
  components: {
    Accordion,
    ProductsCatalog,
    AccordionComponent,
    ViabilityModal,
  },
  computed: {
    ...mapGetters({
      productsComputed: 'saleFlow/product/getProducts',
      getCurrentAddressComputed: 'address/getCurrentAddress',
      authenticatedCustomerComputed: 'auth/AuthorizationCustomer',
      getCookiesPolicyAccepted: 'getCookiesPolicyAccepted'
    }),
  },
    watch: {
    getCookiesPolicyAccepted() {
      if (this.getCookiesPolicyAccepted === true) {
        this.startRegionalizationFlow();
      }
    }
  },
  props: {
    title: String
  },
  methods: {
    ...mapActions({
      getAllProductsCatalogAction: 'saleFlow/getAllProductsCatalog',
      setAvailableProductTypeAction: 'saleFlow/product/setAvailableProductType',
      addProductCartAction: 'cart/addProductCart',
      openCartAction: 'cart/openCart',
      openRegionalizationModalAction: 'address/openRegionalizationModal',
      setRegionalizationModalCallbackAction: 'address/setRegionalizationModalCallback',
      setShowClaroFieldsAction: 'address/setShowClaroFields'
    }),
    getProducts() {
      this.ClaroFieldsAction();
      this.setAvailableProductTypeAction([
        Configuration.productTypeOption[productTypesOptionsConstants.CLARO_RESIDENTIAL_TV],       
      ])
      .then(() => this.getAllProductsCatalogAction())
    },
    addProduct({ product, promotion, productResidentialPointAdditionalAmount, productResidentialPointAdditionalType }) {  
      this.productSelectedToAddCart = {
        product,
        promotion,
        productResidentialPointAdditionalAmount,
        productResidentialPointAdditionalType
      };
        if(this.productSelectedHasViability === true){
           this.showViabilityModal = false
           this.closeViabilityCallback()
        }else{
          this.showViabilityModal = true 
        }
    },
    closeViabilityCallback() {
      if (this.productSelectedHasViability) {
          this.addProductCartAction({
          product: this.productSelectedToAddCart?.product,
          promotion: this.productSelectedToAddCart?.promotion,
          productResidentialPointAdditionalAmount: this.productSelectedToAddCart?.productResidentialPointAdditionalAmount,
          productResidentialPointAdditionalType: this.productSelectedToAddCart?.productResidentialPointAdditionalType
        });
      }
    },
     setProductViabilityCallback({ validated }) {  
      this.productSelectedHasViability = validated;
    },
    openCart() {
      this.openCartAction()
    },
    ClaroFieldsAction(){
      this.setShowClaroFieldsAction()
    },
    openViability() {
      this.showViabilityModal = true
    },    
    close(){
      this.showViabilityModal = false
    },
    startRegionalizationFlow () {
      if (this.getCurrentAddressComputed?.id) {
        this.getProducts()
        return
      }
      this.openRegionalizationModalAction()
      this.setRegionalizationModalCallbackAction(() => {
        this.getProducts()
      })
    },
  }
}
</script>   
<style lang="scss" scoped>
.h1 {
  font-family: "Font Awesome 5 Pro", "Font Awesome 5 Free";
  font-size: 20px;
  color: #000000;
}
.cardRed {
  color: #FFFFFF;
  background-color: crimson;
}
.cardWhite {
  color: crimson;
  background-color: #FFFFFF;
}
.colorBlack {
  color: #000;
}
.colorRed {
  color: crimson;
}
.colorWhite {
  color: #FFFFFF;
  font-weight: 700;
  font-family: Roboto;
}
.bgColorGray {
  background-color: #F3F3F3;
}
.fontSmall {
  font-size: 12px;
}
.fontMedium {
  font-size: 22px;
}
.gradRed {
  background-image: linear-gradient(deeppink, red);
}

</style>
