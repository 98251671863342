<template>
  <div>
    <div class="col-12 target">
      <Icon name="pin" />
      <a class="change-address" @click="changeAddress()">
        {{ stringAddress }}
      </a>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
import Configuration from "@/services/configuration";

export default {
  name: "AddressBar",
  data() {
    return {
      showModal: false,
      hideDivInHeader: false,
    };
  },
  computed: {
    ...mapGetters({
      getCurrentAddressComputed: "address/getCurrentAddress",
      productsComputed: "saleFlow/product/getProducts"
    }),
    stringAddress() {
      const streetName = this.getCurrentAddressComputed?.streetName || "";
      const cityName = this.getCurrentAddressComputed?.cityName || "";
      const stateName = this.getCurrentAddressComputed?.stateName || "";

      return (
        streetName +
        (streetName && cityName ? ", " : "") +
        cityName +
        (cityName && stateName ? ", " : "") +
        stateName
      );
    }
  },
  methods: {
    ...mapActions({
      getAllProductsCatalogAction: "saleFlow/getAllProductsCatalog",
      setAvailableProductTypeAction: "saleFlow/product/setAvailableProductType",
      openRegionalizationModalAction: "address/openRegionalizationModal",
      setRegionalizationModalCallbackAction:"address/setRegionalizationModalCallback",
      setShowClaroFieldsAction: "address/setShowClaroFields",
      clearCurrentAddressAction: "address/clearCurrentAddress"
    }),
    getProducts() {
      this.ClaroFieldsAction();
      this.setAvailableProductTypeAction([
        Configuration.productTypeOption[
          productTypesOptionsConstants.CLARO_RESIDENTIAL_TV
        ],
      ]).then(() => this.getAllProductsCatalogAction());
    },
    startRegionalizationFlow() {
      if (
        this.getCurrentAddressComputed?.id === null ||
        this.getCurrentAddressComputed?.id === undefined
      ) {
        this.getProducts();
        return;
      }
      this.openRegionalizationModalAction();
      this.setRegionalizationModalCallbackAction(() => {
        this.getProducts();
      });
    },
    ClaroFieldsAction() {
      this.setShowClaroFieldsAction();
    },
    changeAddress() {
      this.clearCurrentAddressAction(this.getCurrentAddressComputed);
      this.startRegionalizationFlow();
      return;
    }
  }
};
</script>
<style lang="scss" scoped>
.text-user {
  font-size: 11px;
  font-weight: 700;
  color: rgb(148, 26, 4);
  text-align: center;
  line-height: 1.2;
}
.change-address {
  color: white;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  line-height: 1.2;
  cursor: pointer;
}
.target {
  background-color: #e3262e;
  color: white;
  font-size: 11px;
  font-weight: 700;
}
</style>
