<template>
  <div :key="accordionKey" id="accordionKey">
    <div class="card">
      <div class="card-header bgColorRed" @click="toggleAccordion">
        <h5 class="mb-0 d-flex align-items-center">
          <div class="flex-grow-1 ms-3"
            data-toggle="collapse"
            data-target="#`collapse${_uid}`"
            aria-expanded="true"
            aria-controls="`collapse${_uid}`" >
            <slot name="title" />
          </div>
          <Icon
            name="chevron-up"
            class="chevron ms-3"
            :class="{ rotate: isOpen }"
          />
        </h5>
      </div>
      <div v-show="isOpen" :id="`collapse${accordionKey}`">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ClaroAccordion",
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    accordionKey: {
      type: [String, Number],
      required: true
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    }
  }
});
</script>
<style lang="scss" scoped>
.bgColorRed {
  background-color: #e3262e;
  border-radius: 0px!important;
  margin-bottom: -4px;
}
.bgColorGray {
  background-color: #f3f3f3;
}
.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
</style>
